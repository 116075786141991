import React from 'react';
import BalanceText from '@/components/BalanceText/BalanceText';

interface Props {
    text?: string;
    className?: string;
    style?: any;
    children?: string | object;
    as?: React.ElementType;
    balanced?: boolean;
}

export const BaseText = ({ text = null, className = '', children = null, style = null }: Props) => {
    return (
        <p className={`
            select-none
            w-full
            mx-auto

            font-Inter 
            text-[#4B5563]
            
            ${className}
        `} style={style}>
            <>
                {text ?? children}
            </>
        </p>
    );
};

export const NormalText = ({ text = null, as = 'p', className = '', children = null, style = null, balanced = false }: Props) => {
    let As = as;
    let content = (<>{text}{children}</>);
    if (balanced) {
        content = <BalanceText>{content}</BalanceText>;
        if (as === 'p') {
            As = 'div';
        }
    }
    return (
        <As className={`
            select-none
            w-full
            mx-auto

            font-Inter 
            text-[#4B5563]

            leading-[1.4] 
            text-left 

            text-[17px]
            md:text-[18px] 
            lg:text-[18px] 
            xl:text-[19px] 
            2xl:text-[20px]
             
            ${className}
        `}
        style={style}
        >
            <>
                {content}
            </>
        </As>
    );
};

export const LargeText = ({ text = null, as = 'p', className = '', children = null, style = null, balanced = false }: Props) => {
    let As = as;
    let content = (<>{text}{children}</>);
    if (balanced) {
        content = <BalanceText>{content}</BalanceText>;
        if (as === 'p') {
            As = 'div';
        }
    }

    return (
        <As className={`
            select-none
            
            w-full 
            mx-auto 

            text-[#4B5563]
            text-left 

            font-Inter
            font-normal 
    
            leading-[1.4]
            
            md:text-[16px] 
            lg:text-[18px] 
            xl:text-[20px] 
            2xl:text-[22px]
         
            ${className}
        `}
        >
            {content}
        </As>
    );
};

export const SmallerText = ({ text = null, className = '', children = null, style = null }: Props) => {
    // text-[12px]
    // md:text-[14px]
    // lg:text-[14px]
    // xl:text-[16px]
    // 2xl:text-[18px]
    // 2xl:text-[20px]
    return (
        <p className={`
            select-none
            w-full
            mx-auto 

            font-light
            font-Inter 
        
            text-[14px]
            md:text-[15px]
            lg:text-[15px]
            xl:text-[16px]
            2xl:text-[17px]
            
            text-[#4B5563]
            text-left 

            leading-[1.33]
            
            ${className}
        `}>
            <>
                {text}{children}
            </>
        </p>
    );
};

// {balanced ? (
//         <BalanceText>
//             {text}
//             {children}
//         </BalanceText>
//     )
//     :

export default NormalText;
